<template>
  <section class="h-screen w-screen">
    <div class="flex flex-col h-full items-center justify-center mx-auto">
      <div
        class="w-full flex lg:w-1/2 rounded-lg shadow bg-[#F4F7F9] dark:border dark:bg-gray-800 dark:border-gray-700"
        style="height: 550px"
      >
        <div class="flex flex-col w-full md:w-1/2">
          <form @submit.prevent="onSubmit" class="my-auto">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1
                class="text-xl font-bold leading-tight tracking-tight text-[#333333] md:text-2xl dark:text-white"
              >
                Entrar na sua conta
              </h1>

              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-[#333333] dark:text-white"
                  >E-mail</label
                >
                <BaseTextField
                  validationName="email"
                  placeholder="Digite seu e-mail"
                  :errors="errors?.email"
                  v-model="email"
                  type="email"
                ></BaseTextField>
              </div>
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-[#333333] dark:text-white"
                  >Senha</label
                >
                <BaseTextField
                  validationName="password"
                  placeholder="Insira sua senha"
                  :errors="errors?.password"
                  v-model="password"
                  type="password"
                ></BaseTextField>
              </div>

              <div class="mt-2 flex relative flex-col">
                <div>
                  <div class="text-center sm:text-left mt-4 mb-4 sm:mt-0">
                    <router-link
                      to="/forgotten"
                      class="text-[#54595F] dark:text-white"
                      >Esqueci a minha senha</router-link
                    >
                  </div>
                </div>
                <div class="absolute right-0" v-if="!isMobile && !md">
                  <BaseButton
                    type="submit"
                    text="Entrar"
                    :class="{
                      'opacity-50 cursor-not-allowed':
                        errors?.email || errors?.password,
                    }"
                    :disabled="
                      errors?.email ||
                      errors?.password ||
                      email.value === '' ||
                      password.value === ''
                    "
                  >
                    Entrar
                  </BaseButton>
                </div>
                <div class="mt-2 text-center" v-if="md">
                  <BaseButton
                    type="submit"
                    text="Entrar"
                    :class="{
                      'opacity-50 cursor-not-allowed':
                        errors?.email || errors?.password,
                    }"
                    :disabled="
                      errors?.email ||
                      errors?.password ||
                      email.value === '' ||
                      password.value === ''
                    "
                  >
                    Entrar
                  </BaseButton>
                </div>
                <div v-if="errorMessage" class="text-red-500 pt-5">
                  {{ errorMessage }}
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="md:w-1/2" v-if="!isMobile">
          <img src="/img/login.png" class="h-full w-full rounded-r-lg" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useForm, Form } from "vee-validate";
import * as yup from "yup";
import { useAuthUserStore } from "@/store/users";

const useAuthStore = useAuthUserStore();
const { setAuthenticatedUser } = useAuthStore;

const { errors } = useForm({
  validationSchema: yup.object({
    email: yup.string().email().required().label("E-mail"),
    password: yup.string().required().label("Senha"),
  }),
});

const email = ref("");
const password = ref("");
const errorMessage = ref("");

const onSubmit = async () => {
  try {
    const result = await useFetch("/api/auth/login", {
      method: "POST",
      body: JSON.stringify({
        email: email.value,
        password: password.value,
      }),
    });

    if (
      result.error &&
      result.error.value &&
      result.error.value.data &&
      result.error.value.data.message
    ) {
      errorMessage.value = result.error.value.data.message;
    }

    navigateTo("/");
  } catch (error) {
    console.error("Erro durante a tentativa de login:", error);
  }
};

const isMobile = ref(false);
const md = ref(false);

onMounted(() => {
  // Use window.innerWidth para verificar o tamanho da tela após a montagem do componente.
  const screenWidth = window.innerWidth;
  isMobile.value = screenWidth <= 640;
  md.value = screenWidth <= 1280;
});

definePageMeta({
  layout: "blank",
});
</script>

<style scoped>
.dark\:border {
  border-width: 0px;
}
</style>
```
